import { defineStore }        from 'pinia'
import {axios, serverAddress} from '@/main'
import router                 from "@/router";

import {ref, computed, onMounted} from 'vue'

export const useUserStore = defineStore('user', () => {
    const setLS = (key, val) => { localStorage.setItem(key, val) }
    const getLS = (key) => { return localStorage.getItem(key) }

    const userId   = ref(getLS('userId'))
    const userName = ref(getLS('userName'))
    const userRole = ref(getLS('userRole'))
    const userToken = ref(getLS('userToken'))

    const getUserId = computed(() => ['null', 'undefined', null, undefined, ''].includes(userId.value) ? null : parseInt(userId.value))
    const getUserName = computed(() => userName.value)
    const getUserRole = computed(() => ['null', 'undefined', null, undefined, ''].includes(userRole.value) ? null : parseInt(userRole.value))
    const getUserToken = computed(() => ['null', 'undefined', null, undefined, ''].includes(userRole.value) ? null : parseInt(userToken.value))

    const setUserId = (val) => {
        userId.value = val
        setLS('userId', val)
    }
    const setUserName = (val) => {
        userName.value = val
        setLS('userName', val)
    }
    const setUserRole = (val) => {
        userRole.value = val
        setLS('userRole', val)
    }
    const setUserToken = (val) => {
        userToken.value = val
        setLS('userToken', val)
    }

    const updateUserFromServer = () => {
        axios({}, 'api/user.php?get-user-data').then(r => {
            if (r.data != null && r.data !== []) {
                setUserId(r.data.ID)
                setUserRole(r.data.role)
                setUserName(r.data.name)
                setUserToken(r.data.token)
            } else {
                logOut()
            }
        })
    }

    const logOut = () => {
        localStorage.removeItem('userId')
        localStorage.removeItem('userName')
        localStorage.removeItem('userRole')
        localStorage.removeItem('userToken')

        axios({}, 'api/user.php?logout').then(() => location.href = '/')
    }

    return {
        updateUserFromServer,

        getUserId,
        getUserName,
        getUserRole,
        getUserToken,
        setUserId,
        setUserName,
        setUserRole,
        setUserToken,

        logOut
    }

})
