import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import ax from 'axios'
import {createPinia} from "pinia";

import { DateTime } from 'luxon'

import './assets/main.scss'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/custom.css'

const pinia = createPinia()

export const serverAddress = process.env.NODE_ENV === 'development' ? 'http://localhost/' : location.protocol + '//' + location.host + '/'

export const axios = (data = {}, url = '', method = 'post', async = false) => {
  ax.defaults.withCredentials = true
  ax.defaults.maxContentLength = Infinity
  ax.defaults.maxBodyLength = Infinity
  ax.defaults.headers = { "Content-Type": "application/json" }
  let config = {
    url: serverAddress + url,
    method: method.toLowerCase(),
    [method.toLowerCase() === 'get' ? 'params' : 'data'] : data,
    // signal: abortAllXHR.signal
  }

  if (async === true) {
    async function afd() {
      await ax.request(config)
    }
    return afd()
  } else {
    return ax.request(config)
  }
}

const app = createApp(App)

export const luxon = DateTime

app.config.globalProperties.luxon = DateTime
app.config.globalProperties.serverAddress = serverAddress

app.config.globalProperties.numberFormat = function(number) {
  return new Intl.NumberFormat('hu-HU').format(parseFloat(number))
}

import Vueform from '@vueform/vueform/plugin'
import vueformConfig from './../vueform.config'
app.use(Vueform, vueformConfig)

app.use(pinia)
app.use(router)
app.mount('#app')