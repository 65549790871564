import { createRouter, createWebHistory } from 'vue-router'
import {useUserStore}       from "@/store/user";
import {computed, nextTick} from "vue";

const routes = [
  {
    path: '/home',
    name: 'Home',
    alias: ['/'],
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('../views/Login.vue'),
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  {
    path: '/new-login/:token',
    name: 'NewLogin',
    component: () => import('../views/NewLogin.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/printable',
    name: 'Printable',
    component: () => import('../views/Printable.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  userStore.updateUserFromServer()
  
  const userId = userStore.getUserId
  
  next()
})

export default router
