<template>
	<router-view></router-view>
</template>

<script>



</script>

<style lang="scss">
#app {
	min-height: 100vh;
}


</style>
